var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-skeleton",
    { attrs: { active: "", loading: _vm.loading, paragraph: { rows: 17 } } },
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c("a-alert", { attrs: { type: "info", showIcon: true } }, [
            _c(
              "div",
              { attrs: { slot: "message" }, slot: "message" },
              [
                _vm._v(
                  "\n        上次更新时间：" + _vm._s(this.time) + "\n        "
                ),
                _c("a-divider", { attrs: { type: "vertical" } }),
                _c("a", { on: { click: _vm.handleClickUpdate } }, [
                  _vm._v("立即更新"),
                ]),
              ],
              1
            ),
          ]),
          _c("a-table", {
            staticStyle: { "margin-top": "20px" },
            attrs: {
              rowKey: "id",
              size: "middle",
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              pagination: false,
              loading: _vm.tableLoading,
            },
            scopedSlots: _vm._u([
              {
                key: "param",
                fn: function (text, record) {
                  return [
                    _c(
                      "a-tag",
                      { attrs: { color: _vm.textInfo[record.param].color } },
                      [_vm._v(_vm._s(text))]
                    ),
                  ]
                },
              },
              {
                key: "text",
                fn: function (text, record) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.textInfo[record.param].text) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "value",
                fn: function (text, record) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(text) +
                        " " +
                        _vm._s(_vm.textInfo[record.param].unit) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }